import { HomeViewContext, HomeViewProps } from '..';
import { BCMSParserFunction } from './parser';

export const HomeParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.home.forEach((entry) => {
      const trending = entry.meta.featured_pages.find((p) => p.trending) as any;
      const spotlights = entry.meta.featured_pages.filter(
        (p) => !p.trending,
      ) as any;

      const props: HomeViewProps = {
        page: {
          title: entry.meta.title,
          slug: entry.meta.slug,
          hero: entry.meta.hero,
          trending:
            trending &&
            (trending.blog?.meta?.en?.title ||
              trending.service?.meta?.en?.title)
              ? {
                  title:
                    trending.blog?.meta?.en?.title ||
                    trending.service?.meta?.en?.title ||
                    '',
                  slug:
                    (trending.blog?.meta?.en?.slug
                      ? `/our-insights/blog/${trending?.blog?.meta?.en?.slug}`
                      : '') ||
                    `${trending.service?.meta?.en?.page_group?.selected?.toLowerCase()}/${
                      trending.service?.meta?.en?.slug
                    }`,
                  description:
                    trending.blog?.meta?.en?.description ||
                    trending.service?.meta?.en?.description ||
                    '',
                  image:
                    trending.blog?.meta?.en?.cover_image ||
                    trending.service?.meta?.en?.cover_image?.uri ||
                    '',
                }
              : undefined,
          spotlights_heading: entry.meta.spotlights_heading,
          spotlights: spotlights.map((e: any) => {
            return {
              title:
                e.blog?.meta?.en?.title || e.service?.meta?.en?.title || '',
              slug: e.blog?.meta?.en?.slug
                ? `/our-insights/blog/${e.blog?.meta?.en?.slug}`
                : '' ||
                  `${e?.service?.meta?.en?.page_group?.selected?.toLowerCase()}/${
                    e.service?.meta?.en?.slug
                  }` ||
                  '/',
              description:
                e.blog?.meta?.en?.description ||
                e.service?.meta?.en?.description ||
                '',
              image:
                e.blog?.meta?.en?.cover_image ||
                e.service?.meta?.en?.cover_image?.uri ||
                '',
              icon: e.icon,
            };
          }),
          link_groups: entry.meta.link_groups,
          map: entry.meta.map,
        },
      };
      const context: HomeViewContext = {
        page: JSON.stringify(props.page),
      };
      createPage({
        path: '/',
        component,
        context,
      });
    });
  },
);
